html, body {
  height: 100%;
  margin: 0;
  overflow: hidden;
  

}
body {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #211f21;
}
.container{
  height: 100vh;
  width: 100vw;

    display: flex;
    flex-direction: row-reverse;
     align-items: center;
}
.Item {
    flex: 1;
  margin: 20px;
  position: relative;
  height: 90vh;
  width:  95vh;
  background-color: #151415;

}
.ImgItem{
  margin-top: 1cm;
  margin-bottom:0.5cm;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;

}
.AddToLeaderboard{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 80%;
  left: 50%;
  margin-top: 10vh;
  transform: translate(-50%, -50%);
}

.Button-container{
  width: 100vw;
  height: 100vh;

  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    position: fixed;
    left: 0%;

    z-index: 1

  
}
.MainText{
  font-size: 50px;
  text-align: center; 
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: pre-wrap; 
}

.MainText p{
  top: 15%;
  color:white;
  padding: 0px 177px 0px;
    font-weight: bold;
    width:90vw;
    min-width:300px;
}
.SecondaryText{

  font-size: 30px;
  color:white;
  text-align: center; 
  position: absolute;
  top: 35%;
  left: 50%;
  width: 90vw;
  transform: translate(-50%, -50%);
  white-space: pre-wrap; 
}
.square{
width: 100px;
height: 100px;
display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  font-size: 40px;
  border: solid 1px rgb(55, 54, 54);
}
.squareGreen{
  background-color: green;
  color: white;
}
.squareRed{
  background-color: rgb(128, 0, 0);
  color: red;
}
.Hide{
  display: none;
}
.ButtonPlayP{
  margin-top: 1px;

  position: absolute;
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%);

}
.Lost{

   position: absolute;
    top: 30%;
    left: 50%; 

    transform: translate(-50%, -50%);
    text-align: center;
    display: table-column;
    justify-self: center;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    color:white

}

.LText{
  font-size: 50px;
  width: 95vw;
}
.LScore{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 70px;
}

.Score{
  position: fixed;
  top: calc(100% - 50px);
  left: calc(100% - 150px);
  transform: translate(-100%,-100%);
  
  width: 150px;
  height: 50px;
  font-size: 34px;
  color: white;
}

.Price{
    position: absolute;
    margin-top: 0.5cm;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #d2cf13;
    padding: 10px;
    white-space: nowrap;
    font-size: 90px;
    z-index: 1
}

.TextJmeno {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    margin-bottom: 1cm;
  }
  .Text{
    color:white;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .Wiki{
    position: absolute;
    top: 100%;
    left: 100%;
    transform: translate(-100%, -100%);
    color: #1316d2;
    white-space: nowrap;
    font-size: 17px;
    z-index: 1

  }

.Button {
    width: 150px;
    height: 100px;
    align-items: center;
    
    appearance: none;

    background-color: #1af15b;
    background-image: linear-gradient(1deg, #1af15b, #19a63c 99%);
    background-size: calc(100% + 20px) calc(100% + 20px);
    border-radius: 100px;
    border-width: 0;
    box-shadow: none;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: CircularStd,sans-serif;
    font-size: 1rem;
    height: auto;
    justify-content: center;
    line-height: 1.5;
    margin: 4px;
    padding: 6px 20px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: background-color .2s,background-position .2s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: top;
    white-space: nowrap;
}

.Button:active,
.Button:focus {
  outline: none;
}

.Button:hover {
  background-position: -20px -20px;
}

.Button:focus:not(:active) {
  box-shadow: rgba(40, 255, 108, 0.25) 0 0 0 .125em;
}

.ButtonGame div{
  width: 2vw;
  height: 2vh;
  transform: translate(-50%,-50%);
}

.ButtonGame{
  width: 10vw;
  height: 10vh;
  position: absolute;
  top: 45vh;
  left: 71vw;
}
.ButtonGame button{
  background-color: rgba(0, 0, 0, 0.05);
  background-image: none;

  border: solid 3px green;
}
.ButtonGame Button:hover {
  background-color: rgba(0, 0, 0, 0.6);
}
.circle {
  width: 90px;
  height: 90px;
  border-radius: 50%; /* This makes the element a circle */
  background-color: rgb(49, 47, 47); /* Optional: Add a background color to fill the circle */
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.vs-text {
  color: white; /* Optional: Set the text color */
  font-size: 30px; /* Set the font size */
  font-weight: bold;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.Leaderboard {
  width: 150vh;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: calc(100vh - 100px); /* Adjust this value to leave space for other elements */
  overflow: auto; /* Enable scrolling */
  background-color: #151415;
  color: white;
  
}

.data-table {
  font-weight: 100;
  font-size: 46px;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  border-collapse: collapse;
  width: 100%;
  overflow: auto; /* Enable scrolling within the table if needed */
}

.data-table th,
.data-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
.data-table tbody tr:nth-child(odd) {
  background-color: #212021; /* Set the color for odd rows */
}
.data-table tbody tr:nth-child(even) {
  background-color: transparent; /* Set the color for even rows */
}

.BBack{
  left: 50%;
  transform: translate(-50%, 10%);
}
input {
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease-in-out;
}


input:focus {
  border-color: #3c89bc;
  box-shadow: 0 0 10px rgba(52, 152, 219, 0.5);
}


input::placeholder {
  color: #999;
}

 @media (max-width: 1200px) {
   body {
     font-size: 80%;
    }
    .AddToLeaderboard{
      position: absolute;
      top: 70vh;
    }
    .data-table {
      font-size: 30px;
      width: 70%;
    }
  .Price {
    font-size: 55px;
  }
  .Button{
    scale: 100%;

  }
  
  .MainText {
    font-size: 50px;
    top: 20%;
  

  }
  .SecondaryText {
    font-size: 30px;
    top: 35%;
    
  }
 }

 @media (max-width: 800px) {
  body {
    font-size: 60%;
  }
  .AddToLeaderboard{
    position: absolute;
    top: 70vh;
  }
  .data-table {
    font-size: 30px;
    width: 50%;
  }

  .TextJmeno{
    font-size: 30px;
  }

  .Price {
    font-size: 40px;
  }

  .Button{  
    scale: 100%;
    margin: 4px;

  }
  
  .MainText p{
    font-size: 40px;
    top: 15%;

  }
  .SecondaryText {
    font-size: 25px;
    top: 35%;
    
  }
 }

 @media (max-width: 600px) {
  body {
    font-size: 60%;
  }
  .AddToLeaderboard{
    position: absolute;
    top: 70vh;
  }
  .data-table {
    font-size: 20px;
    width: 20%;
  }
  
.ImgItem{
  margin-top: 1cm;
  margin-bottom:0.5cm;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 40%;

}

  .Button-container{
    width: 100vw;
    height: 100vh;
  
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    position: fixed ;
    left: 0%;
    top: 0%;

      z-index: 1
  
    
  }

  .Item {
    flex: 1;
  margin: 20px;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #151415;

}
  .container{

    height: 100vh;
    width: 100vw;
  
    display: flex;
    flex-direction: column-reverse;
     align-items: center;
}


.TextJmeno{
  font-size: 25px;
  margin-bottom:5px;
}

.Score{
  top:calc(50vh - 25px);
   left: calc(0% - 0px); 
   
}

.ButtonGame{
   width: 10vw;
   height: 10vh;
   
   position: absolute;
   top: 70vh;
   left: 35vw;
}
  .Price {
    font-size: 50px;
    top: 77%;
  }
  .Button{
    scale: 100%;
    margin:4px
  }
  
  .MainText p{
    font-size: 30px;
    top: 15%;

  }
  .SecondaryText {
    font-size: 15px;
    top: 35%;
    
  }


 }

 @media (max-width: 350px) {
  body {
    font-size: 60%;
  }
  .AddToLeaderboard{
    position: absolute;
    top: 70vh;
  }
  .data-table {
    font-size: 17px;
    width: 10%;
  }

  .ButtonGame{
    width: 10vw;
    height: 10vh;
    
    position: absolute;
    top: calc(70vh);
    left: calc(25vw);
  }

  .Price {
    font-size: 40px;
  }
  .Button{
    scale: 100%;
    margin: 4px;
  }
  
  .MainText p{
    font-size: 30px;
    top: 15%;

  }
  .SecondaryText {
    font-size: 15px;
    top: 35%;
    
  }
 }

 @media (min-width: 2560px) {
  body {
    font-size: 150%;
  }
  .data-table {
    font-size: 69px;
  }
  .TextJmeno{
    font-size: 65px;
  }
  .Price {
    font-size: 150px;
  }
  .Button{
    scale: 150%;
    margin: 25px;
    margin-right: 50px;
    margin-left: 50px;
  }
  input {
    scale: 150%;
 
  }
  .AddToLeaderboard button{
    margin: 0px;
    margin-top: 35px;
  }
  
  .MainText {
    font-size: 60px;
  }
  .SecondaryText {
    font-size: 40px;
    
  }
 }

 @media (min-width: 3840px) {

  body {
    font-size: 230%;
  }
  .data-table {
    font-size: 113px;
  }
  .Price {
    font-size: 200px;
  }
 
  .MainText{
    font-size: 70px;

  }
  .SecondaryText{
    font-size: 50px;

  }
  .TextJmeno{
    font-size: 70px;
  }

  
 }